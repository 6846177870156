import { Divider, Stack, Typography } from "@mui/material";
import { RentalProperty } from "../models";

export function LayoutAndArea(props: {
  property: RentalProperty;
  layoutWeight?: number;
  areaWeight?: number;
}) {
  const { property, layoutWeight, areaWeight } = props;
  return (
    <Stack direction="row" gap={1}>
      {property.apartment.layout !== "" && (
        <>
          <Typography component="span" fontWeight={layoutWeight}>
            {property.apartment.layout}
          </Typography>
          <Divider orientation="vertical" flexItem />
        </>
      )}
      <Typography component="span" fontWeight={areaWeight}>
        {property.apartment.area}㎡
      </Typography>
    </Stack>
  );
}
