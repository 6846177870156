import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Building, RentalProperty } from "../models";
import { ApartmentTitle } from "./PropertyCard";
import {
  Architecture,
  House,
  Light,
  LocalParking,
  Place,
  PriorityHigh,
} from "@mui/icons-material";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { LayoutAndArea } from "./LayoutAndArea";

export default function PropertyDialog() {
  const navigate = useNavigate();
  const properties = useOutletContext<RentalProperty[]>();
  const { propertyId } = useParams();
  const property = properties.find((p) => p.key === propertyId);

  if (!property) {
    return <></>;
  }

  return (
    <Dialog open={true} fullWidth onClose={() => navigate("..")}>
      <DialogTitle>
        <ApartmentTitle property={property} />
      </DialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        <Box textAlign="right">
          <Typography variant="caption" color="gray">
            {new Date(property.updatedTime).toLocaleDateString() + "更新"}
          </Typography>
        </Box>
        <List>
          <ListItem>
            <StyledListItemIcon children={<Architecture />} />
            <ListItemText primary={<LayoutAndArea property={property} />} />
          </ListItem>
          <ListItemButton
            href={`https://maps.google.com/?q=${property.building.address}`}
            target="_blank"
          >
            <StyledListItemIcon children={<Place />} />
            <ListItemText primary={property.building.address} />
          </ListItemButton>
          <ListItem>
            <StyledListItemIcon children={<House />} />
            <ListItemText primary={buildingSummary(property.building)} />
          </ListItem>
          <ListItem>
            <StyledListItemIcon children={<LocalParking />} />
            <ListItemText primary={property.contract.parking} />
          </ListItem>
          {property.apartment.facilities.length !== 0 && (
            <ListItem>
              <StyledListItemIcon children={<Light />} />
              <ListItemText
                primary="設備"
                primaryTypographyProps={{
                  variant: "caption",
                  color: "text.secondary",
                }}
                secondary={
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    gap={0.5}
                    component="span"
                  >
                    {property.apartment.facilities.map((f) => (
                      <Chip key={f} label={f} size="small" component="span" />
                    ))}
                  </Stack>
                }
              />
            </ListItem>
          )}
          {(property.contract.conditions.length !== 0 ||
            property.contract.fixedTerm) && (
            <ListItem>
              <StyledListItemIcon children={<PriorityHigh />} />
              <ListItemText
                primary="契約条件"
                primaryTypographyProps={{
                  variant: "caption",
                  color: "text.secondary",
                }}
                secondary={
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    gap={0.5}
                    component="span"
                  >
                    {property.contract.fixedTerm && (
                      <Chip
                        size="small"
                        component="span"
                        label="定期借家"
                        color="secondary"
                      />
                    )}
                    {property.contract.conditions.map((f) => (
                      <Chip key={f} label={f} size="small" component="span" />
                    ))}
                  </Stack>
                }
              />
            </ListItem>
          )}
        </List>
        {property.images.map((img) => (
          <Stack key={img.url}>
            <Divider />
            <img src={img.url} alt="" style={{ maxWidth: "100%" }} />
          </Stack>
        ))}
      </DialogContent>
    </Dialog>
  );
}

const buildingSummary = (building: Building) =>
  stringifyStructure(building) +
  (building.story.ground !== 0 ? ` ${building.story.ground}階建` : "");

const stringifyStructure = (building: Building) => {
  switch (building.structure) {
    case "wood":
      return "木造";
    case "block":
      return "ブロック造";
    case "steel_frame":
      return "鉄骨造";
    case "reinforced_concrete":
      return "RC造";
    case "steel_reinforced_concrete":
      return "SRC造";
    case "precast_concrete":
      return "PC造";
    case "high_performance_precast_concrete":
      return "HPC造";
    case "light_gauge_steel":
      return "軽量鉄骨造";
    case "autoclaved_aerated_concrete":
      return "ALC造";
    case "reinforced_block":
      return "鉄筋ブロック造";
    case "concrete_filled_tubular":
      return "CFT造";
    case "unknown":
      return "不明";
  }
};

const StyledListItemIcon = styled(ListItemIcon)({ minWidth: 40 });
