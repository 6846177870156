import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { CssBaseline } from "@mui/material";
import PropertiesScreen from "./components/PropertiesScreen";
import AboutUsView from "./components/AboutUsView";
import { RootScreen } from "./components/RootScreen";
import PropertyDialog from "./components/PropertyDialog";

const router = createBrowserRouter([
  {
    Component: RootScreen,
    children: [
      { path: "aboutus", Component: AboutUsView },
      {
        path: ":purpose",
        Component: PropertiesScreen,
        children: [{ path: ":propertyId", Component: PropertyDialog }],
      },
      { path: "*", element: <Navigate replace to="/residence" /> },
    ],
  },
]);

export default function App() {
  return (
    <>
      <CssBaseline />
      <RouterProvider router={router} />
    </>
  );
}
