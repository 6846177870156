import { Stack, Typography } from "@mui/material";
import { RentRelativeFee } from "../models";

export function ContractProp(props: {
  prefix?: string;
  value: string | number;
  suffix?: string;
}) {
  const { prefix, value, suffix } = props;
  if (value === 0) {
    return <ContractProp {...props} value="なし" suffix="" />;
  }
  if (typeof value === "number") {
    return <ContractProp {...props} value={value.toLocaleString()} />;
  }
  return (
    <Stack direction="row" alignItems="baseline" gap={0.2}>
      <Typography variant="caption">{prefix}</Typography>
      <Typography fontSize={18} fontWeight={500}>
        {value}
      </Typography>
      <Typography variant="caption">{suffix}</Typography>
    </Stack>
  );
}

export function RentRelativeFeeView(props: {
  prefix: string;
  value: RentRelativeFee;
}) {
  const { prefix, value } = props;
  return value.month !== 0 ? (
    <ContractProp prefix={prefix} value={value.month} suffix="ヶ月" />
  ) : (
    <ContractProp prefix={prefix} value={value.amount} suffix="円" />
  );
}
