import {
  Box,
  IconButton,
  Paper,
  Typography,
  styled,
  Stack,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import moment from "moment";

const elapsed = moment(new Date()).diff(moment([1969, 7 - 1, 26]), "years");

export default function HeroUnit(props: {
  compact: boolean;
  onOpen: () => void;
}) {
  const { compact, onOpen } = props;
  return (
    <StyledPaper elevation={4} square>
      <Stack minHeight={300} justifyContent="center" alignItems="center">
        <StyledTitle variant="h2">(株)吉川不動産 </StyledTitle>
        <StyledTagline variant="body1">
          地域に根ざして{elapsed}年、豊富な経験をもとに皆様のお役に立ちます。
        </StyledTagline>
      </Stack>
      <Box hidden={!compact} sx={{ position: "fixed", left: 16, top: 6 }}>
        <IconButton color="inherit" onClick={onOpen}>
          <Search />
        </IconButton>
      </Box>
    </StyledPaper>
  );
}

const StyledPaper = styled(Paper)({
  color: "#FFF",
  backgroundImage: "url(/bright-blue-sky-with-a-few-tiny-white-clouds.jpg)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "bottom",
  position: "sticky",
  top: -240,
  zIndex: 2,
});

const StyledTitle = styled(Typography)({
  textShadow: "0 2px 3px #000",
  // fontSize: "350%",
  // fontWeight: "bold",
  marginLeft: 20,
  marginRight: 20,
  fontSize: "min(12.5vw, 3.75rem)",
  fontFamily: `"M PLUS Rounded 1c"`,
  fontWeight: 700,
});

const StyledTagline = styled(Typography)({
  textShadow: "0 1px 2px #000",
  fontWeight: 700,
  marginLeft: 20,
  marginRight: 20,
});
