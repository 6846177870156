import { Link } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Apartment, RentalProperty } from "../models";
import { ContractProp, RentRelativeFeeView } from "./ContractProp";
import { LayoutAndArea } from "./LayoutAndArea";
import { Diff } from "diff";
import { useMemo } from "react";

export default function PropertyCard(props: { property: RentalProperty }) {
  const { property } = props;

  const transits = property.building.transits
    .map((t) => `${t.station} 徒歩${t.walkMinutes}分`)
    .join(" / ");

  return (
    <Card sx={{ my: 2 }}>
      <CardActionArea component={Link} to={property.key}>
        <Stack
          bgcolor="#F4F4F4"
          direction="row"
          position="relative"
          spacing={"1px"}
        >
          {property.images.map((img) => (
            <CardMedia
              key={img.url}
              component="img"
              image={img.url}
              height={120}
              sx={{ objectFit: "contain" }}
            />
          ))}
          <Chip
            sx={{ position: "absolute", left: 8, top: 8 }}
            color="primary"
            label={
              <LayoutAndArea
                property={property}
                layoutWeight={700}
                areaWeight={500}
              />
            }
          />
        </Stack>

        <CardHeader
          title={<ApartmentTitle property={property} />}
          titleTypographyProps={{ variant: "h6" }}
          subheader={transits}
        />

        <CardContent sx={{ pt: 0 }}>
          {property.contract.fixedTerm && (
            <Chip label="定期借家" color="secondary" />
          )}
          <Stack direction="row" gap={2}>
            <ContractProp
              prefix="賃料"
              value={(property.contract.rent / 10000).toString()}
              suffix="万円"
            />
            <Divider orientation="vertical" flexItem />
            <ContractProp
              prefix="共益費"
              value={property.contract.communalFee}
              suffix="円"
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <RentRelativeFeeView
              prefix="敷金"
              value={property.contract.deposit}
            />
            <Divider orientation="vertical" flexItem />
            <RentRelativeFeeView
              prefix="礼金"
              value={property.contract.keyMoney}
            />
            {property.contract.freeRent !== 0 && (
              <>
                <Divider orientation="vertical" flexItem />
                <ContractProp
                  prefix="ﾌﾘｰﾚﾝﾄ"
                  value={property.contract.freeRent}
                  suffix="ヶ月"
                />
              </>
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export function ApartmentTitle(props: { property: RentalProperty }) {
  const { property } = props;

  const title = useMemo((): (string | { rb: string; rt: string })[] => {
    let result: (string | { rb: string; rt: string })[] = [""];
    const d = new Diff();
    d.tokenize = (value: string): string[] =>
      value
        .split(/(アパート|マンション|貸家|秋津町?|青葉町?|所沢|.)/)
        .filter((token) => token !== "");
    d.diff(property.building.name, property.building.nameKana).forEach(
      (change) => {
        if (change.added || change.removed) {
          const last = result[result.length - 1];
          let ruby = { rb: "", rt: "" };
          if (typeof last === "string") {
            result.push(ruby);
          } else {
            ruby = last;
          }
          if (change.added) {
            ruby.rt = change.value;
          } else {
            ruby.rb = change.value;
          }
        } else {
          result.push(change.value);
        }
      }
    );
    return result.slice(1);
  }, [property.building]);

  return (
    <span>
      {title.map((t, i) =>
        typeof t === "string" ? (
          <span key={i}>{t}</span>
        ) : (
          <ruby key={i}>
            {t.rb}
            <rp>(</rp>
            <rt>{t.rt}</rt>
            <rp>)</rp>
          </ruby>
        )
      )}{" "}
      <ApartmentName apartment={property.apartment} />
    </span>
  );
}

function ApartmentName(props: { apartment: Apartment }) {
  const { apartment } = props;
  const suffix = useMemo(() => {
    return apartment.name === "" ||
      apartment.name.endsWith("階") ||
      apartment.name.endsWith("号")
      ? ""
      : "号室";
  }, [apartment.name]);

  return (
    <span>
      {apartment.name}
      {suffix}
    </span>
  );
}
