import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { AccessTime, LocationOn, Mail, Phone } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const AboutUsView: React.FC<{}> = () => (
  <Stack>
    <Container maxWidth="md">
      <Card sx={{ my: 2 }}>
        <CardMedia
          component="img"
          image="office.jpg"
          sx={{ aspectRatio: "1600/1021" }}
        />
        <CardContent>
          <Grid2 container spacing={2}>
            <Grid2 md={6} sm={6} xs={12}>
              <AboutUsList />
            </Grid2>
            <Grid2 md={6} sm={6} xs={12}>
              <dl>
                <dt>名称</dt>
                <dd>株式会社 吉川不動産</dd>
                <dt>免許番号</dt>
                <dd>東京都知事免許(15)14765号</dd>
                <dt>資本金</dt>
                <dd>1,000万円</dd>
                <dt>会社設立</dt>
                <dd>昭和44年7月26日</dd>
              </dl>
            </Grid2>
            <Grid2 md={6} sm={6} xs={12}>
              <Typography variant="h6">事業内容</Typography>
              <ul>
                <li>不動産の売買及び仲介</li>
                <li>貸家・貸室の仲介及び管理</li>
                <li>駐車場の仲介及び管理</li>
                <li>賃貸物件企画</li>
              </ul>
            </Grid2>
            <Grid2 md={6} sm={6} xs={12}>
              <Typography variant="h6">取引銀行</Typography>
              <ul>
                <li>埼玉りそな銀行</li>
                <li>東京みらい農協</li>
                <li>きらぼし銀行</li>
                <li>西武信用金庫</li>
                <li>飯能信用金庫</li>
                <li>多摩信用金庫</li>
              </ul>
            </Grid2>
            <Grid2 md={6} sm={8} xs={12} mdOffset={3} smOffset={2} xsOffset={0}>
              <Typography variant="h6" gutterBottom>
                ご挨拶
              </Typography>
              <Typography variant="body2" gutterBottom>
                創業以来、社長の出生地・東村山市地元の皆様に支えられ、また近郊の方々と深く交流を持ち、ご支援いただきながら現在に至ってまいりました。
                不動産の業務は、単に契約や入居のお手伝いのみにとどまらず、生活全般に関わりをもつようになりますので、何ごとにも誠実に対応して、いつまでもおつき合いをさせて頂きたいと思っています。
              </Typography>
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
    </Container>
  </Stack>
);

export default AboutUsView;

const AboutUsList: React.FC<{}> = () => (
  <List>
    <ListItem button component="a" href="https://goo.gl/maps/4bbfXee8MK72">
      <StyledListItemIcon children={<LocationOn />} />
      <ListItemText primary="東村山市秋津町5-12-5" />
    </ListItem>
    <ListItem button component="a" href="tel:042-391-1111">
      <StyledListItemIcon children={<Phone />} />
      <ListItemText primary="042-391-1111" />
    </ListItem>
    <ListItem button dense component="a" href="mailto:info@yoshikawa-re.co.jp">
      <StyledListItemIcon children={<Mail />} />
      <ListItemText
        primary="info@yoshikawa-re.co.jp"
        secondary="当アドレスへの特定電子メールの送信を拒否します"
        secondaryTypographyProps={{ sx: { fontSize: 10 } }}
      />
    </ListItem>
    <ListItem dense>
      <StyledListItemIcon children={<AccessTime />} />
      <ListItemText
        primary="10時〜17時（水曜定休）"
        secondary="※営業時間を短縮しております"
        secondaryTypographyProps={{ variant: "caption" }}
      />
    </ListItem>
  </List>
);

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 40,
});
