import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import {
  Box,
  Collapse,
  Container,
  Drawer,
  LinearProgress,
  Paper,
  Stack,
  useMediaQuery,
} from "@mui/material";
import HeroUnit from "./HeroUnit";
import FilterBox, { applyFilter, Filter } from "./FilterBox";
import PropertyCard from "./PropertyCard";
import { RentalProperty } from "../models";

const defaultFilter: Filter = {
  layout: 0,
  rentMin: 0,
  rentMax: 1000000,
  station: "any",
  walkTimeMin: 0,
  walkTimeMax: 60,
};

export default function PropertiesScreen() {
  const { purpose } = useParams();
  const [loading, setLoading] = useState(false);
  const compact = useMediaQuery("(max-width:900px)");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [properties, setProperties] = useState<RentalProperty[]>([]);
  const [filter, setFilter] = useState<Filter>(defaultFilter);

  // 募集の取得
  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await fetch(`/api/properties/${purpose}`);
      setLoading(false);
      if (res.ok) {
        const properties = (await res.json()) as RentalProperty[];
        setFilter(defaultFilter);
        setProperties(properties.slice().sort((a, b) => a.order - b.order));
      }
    })();
  }, [purpose]);

  const showFloatingFilter = purpose === "residence" && !compact;

  return (
    <>
      <HeroUnit compact={compact} onOpen={() => setDrawerOpen(true)} />
      <Outlet context={properties} />
      {loading && <LinearProgress />}
      <Container maxWidth="md">
        <Stack direction="row" alignItems="start" gap={2}>
          {showFloatingFilter && (
            <Paper
              elevation={1}
              sx={{
                position: "sticky",
                top: 80,
                p: 2,
                my: 2,
                width: 300,
                flexShrink: 0,
              }}
            >
              <FilterBox
                vacants={properties}
                filter={filter}
                onUpdate={setFilter}
              />
            </Paper>
          )}
          <Stack flexGrow={1}>
            {properties.map((v) => (
              <Collapse key={v.key} in={applyFilter(v, filter)}>
                <PropertyCard property={v} />
              </Collapse>
            ))}
          </Stack>
        </Stack>
        <Drawer
          anchor="top"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Box margin={2}>
            <FilterBox
              vacants={properties}
              filter={filter}
              onUpdate={setFilter}
            />
          </Box>
        </Drawer>
      </Container>
    </>
  );
}
