import { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import {
  Business,
  Download,
  EmojiPeople,
  Handshake,
  Home,
} from "@mui/icons-material";

export function RootScreen() {
  return (
    <Stack width="100%" minHeight="100%" paddingBottom="60px">
      <Outlet />
      <GlobalBottomNav />
    </Stack>
  );
}

function GlobalBottomNav() {
  const [showPartnerDialog, setShowPartnerDialog] = useState(false);
  const location = useLocation();
  const value = ["/residence", "/business", "/aboutus"].indexOf(
    location.pathname
  );

  return (
    <Stack flexShrink={0} position="fixed" bottom={0} width="100%">
      <Divider />
      <BottomNavigation value={value} showLabels>
        <BottomNavigationAction
          component={Link}
          icon={<Home />}
          label="居住用"
          to="/residence"
        />
        <BottomNavigationAction
          component={Link}
          icon={<Business />}
          label="事業用"
          to="/business"
        />
        <BottomNavigationAction
          component={Link}
          icon={<EmojiPeople />}
          label="当社について"
          to="/aboutus"
        />
        <BottomNavigationAction
          icon={<Handshake />}
          label="同業者様へ"
          onClick={() => setShowPartnerDialog(true)}
        />
      </BottomNavigation>
      <PartnerDialog
        open={showPartnerDialog}
        onClose={() => setShowPartnerDialog(false)}
      />
    </Stack>
  );
}

function PartnerDialog(props: { open: boolean; onClose: () => void }) {
  return (
    <Dialog {...props}>
      <DialogContent sx={{ p: 0 }}>
        <List>
          <ListItemButton href="/rental_application_form.pdf" target="_blank">
            <ListItemIcon sx={{ minWidth: 32 }} children={<Download />} />
            <ListItemText primary="入居申込書（個人のお客様）" />
          </ListItemButton>
          <ListItemButton
            href="/rental_application_form_for_business.pdf"
            target="_blank"
          >
            <ListItemIcon sx={{ minWidth: 32 }} children={<Download />} />
            <ListItemText primary="入居申込書（法人のお客様）" />
          </ListItemButton>
          <Divider />
          <ListItemButton
            href="/rental_agreement_checklist.pdf"
            target="_blank"
          >
            <ListItemIcon sx={{ minWidth: 32 }} children={<Download />} />
            <ListItemText primary="入居申込時の確認事項" />
          </ListItemButton>
        </List>
      </DialogContent>
    </Dialog>
  );
}
