import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
} from "react";
import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import { StandardCSSProperties } from "@mui/system";
import { grey } from "@mui/material/colors";
import { RentalProperty } from "../models";

export interface Range {
  min: number;
  max: number;
}

export interface Filter {
  layout: number;
  rentMin: number;
  rentMax: number;
  station: string;
  walkTimeMin: number;
  walkTimeMax: number;
}

export interface FilterBoxProps {
  vacants: RentalProperty[];
  filter: Filter;
  onUpdate: Dispatch<SetStateAction<Filter>>;
}

const FilterBox: React.FC<FilterBoxProps> = ({ vacants, filter, onUpdate }) => {
  const rentRange = useMemo((): Range => {
    return {
      min: vacants.reduce(
        (a, b) => Math.min(a, b.contract.rent + b.contract.communalFee),
        40000
      ),
      max: vacants.reduce(
        (a, b) => Math.max(a, b.contract.rent + b.contract.communalFee),
        50000
      ),
    };
  }, [vacants]);

  const walkTimeRange = useMemo((): Range => {
    const wts = vacants
      .map((v) => getWalkTime(v, filter.station))
      .filter((time) => time !== -1);
    return {
      min: wts.reduce((a, b) => Math.min(a, b), 5),
      max: wts.reduce((a, b) => Math.max(a, b), 10),
    };
  }, [vacants, filter.station]);

  useEffect(() => {
    onUpdate((filter) => ({
      ...filter,
      rentMin: Math.max(filter.rentMin, rentRange.min),
      rentMax: Math.min(filter.rentMax, rentRange.max),
      walkTimeMin: Math.max(filter.walkTimeMin, walkTimeRange.min),
      walkTimeMax: Math.min(filter.walkTimeMax, walkTimeRange.max),
    }));
  }, [onUpdate, rentRange, walkTimeRange]);

  return (
    <Stack gap={2}>
      <FormControl fullWidth variant="filled">
        <InputLabel>🚪 間取り</InputLabel>
        <Select
          label="🚪 間取り"
          value={filter.layout}
          onChange={(event) =>
            onUpdate((filter) => ({
              ...filter,
              layout: event.target.value as number,
            }))
          }
        >
          <MenuItem value={0}>指定なし</MenuItem>
          <MenuItem value={1}>ワンルーム</MenuItem>
          <MenuItem value={2}>1K〜1LDK</MenuItem>
          <MenuItem value={3}>2K〜2LDK</MenuItem>
          <MenuItem value={4}>3K〜3LDK</MenuItem>
        </Select>
      </FormControl>
      <Stack direction="column">
        <Stack direction="row">
          <Box
            position="relative"
            bgcolor={grey[300]}
            flexGrow={1}
            marginRight={1}
            marginBottom={1}
            border={`1px solid ${grey[300]}`}
          >
            <Box
              position="absolute"
              bgcolor={grey[100]}
              left={inPercentage(
                (filter.walkTimeMin - walkTimeRange.min) /
                  (walkTimeRange.max - walkTimeRange.min)
              )}
              width={inPercentage(
                (filter.walkTimeMax - filter.walkTimeMin) /
                  (walkTimeRange.max - walkTimeRange.min)
              )}
              top={inPercentage(
                (filter.rentMax - rentRange.max) /
                  (rentRange.min - rentRange.max)
              )}
              height={inPercentage(
                (filter.rentMax - filter.rentMin) /
                  (rentRange.max - rentRange.min)
              )}
            />
            <GraphAxis />
            <Box position="relative" width="100%" height={220}>
              {vacants.map((v) => {
                const walkTime = getWalkTime(v, filter.station);
                return (
                  <PropertyAvatar
                    key={v.key}
                    property={v}
                    excluded={!applyFilter(v, filter)}
                    excludedByLayout={!filterByLayout(v, filter.layout)}
                    walkTime={walkTime}
                    left={inPercentage(
                      ((walkTime === -1 ? 20 : walkTime) - walkTimeRange.min) /
                        (walkTimeRange.max - walkTimeRange.min)
                    )}
                    top={inPercentage(
                      1 -
                        (v.contract.rent +
                          v.contract.communalFee -
                          rentRange.min) /
                          (rentRange.max - rentRange.min)
                    )}
                  />
                );
              })}
            </Box>
          </Box>
          <Stack paddingTop={1} paddingBottom={1}>
            <Slider
              orientation="vertical"
              value={[filter.rentMin, filter.rentMax]}
              onChange={(_, value) =>
                onUpdate((filter) => ({
                  ...filter,
                  rentMin: (value as number[])[0],
                  rentMax: (value as number[])[1],
                }))
              }
              defaultValue={[rentRange.min, rentRange.max]}
              min={rentRange.min}
              max={rentRange.max}
              step={1000}
              valueLabelDisplay="auto"
              valueLabelFormat={(val) => `${(val / 10000).toFixed(1)}万円`}
            />
          </Stack>
        </Stack>
        <Stack marginRight="30px">
          <Stack paddingLeft={1} paddingRight={1}>
            <Slider
              value={[filter.walkTimeMin, filter.walkTimeMax]}
              onChange={(_, value) =>
                onUpdate((filter) => ({
                  ...filter,
                  walkTimeMin: (value as number[])[0],
                  walkTimeMax: (value as number[])[1],
                }))
              }
              defaultValue={[walkTimeRange.min, walkTimeRange.max]}
              min={walkTimeRange.min}
              max={walkTimeRange.max}
              valueLabelDisplay="auto"
              valueLabelFormat={(val) => `徒歩${val}分`}
            />
          </Stack>
          <FormControl fullWidth variant="filled">
            <InputLabel>🚉 駅</InputLabel>
            <Select
              label="🚉 駅"
              value={filter.station}
              onChange={(event) =>
                onUpdate((filter) => ({
                  ...filter,
                  station: event.target.value,
                }))
              }
            >
              <MenuItem value="any">物件の最寄り駅から</MenuItem>
              <MenuItem value="秋津">秋津駅から</MenuItem>
              <MenuItem value="新秋津">新秋津駅から</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FilterBox;

const GraphAxis: React.FC<{}> = () => (
  <>
    <GraphAxisStack direction="row">
      <Typography fontSize={12}>⬅駅近</Typography>
      <Typography fontSize={12}>駅遠➡</Typography>
    </GraphAxisStack>
    <GraphAxisStack direction="column">
      <Typography fontSize={12} align="center">
        ⬆<br />
        賃料高
      </Typography>
      <Typography fontSize={12} align="center">
        賃料低
        <br />⬇
      </Typography>
    </GraphAxisStack>
  </>
);

const GraphAxisStack: React.FC<{
  direction: "row" | "column";
  children: ReactNode;
}> = ({ direction, children }) => (
  <Stack
    sx={{ color: grey[500] }}
    position="absolute"
    left={0}
    top={0}
    right={0}
    bottom={0}
    direction={direction}
    alignItems="center"
    justifyContent="space-between"
  >
    {children}
  </Stack>
);

interface PropertyAvatarProps {
  property: RentalProperty;
  excluded: boolean;
  excludedByLayout: boolean;
  walkTime: number;
  left: StandardCSSProperties["left"];
  top: StandardCSSProperties["top"];
}

function PropertyAvatar(props: PropertyAvatarProps) {
  const { property, excluded, excludedByLayout, walkTime, left, top } = props;

  return (
    <Avatar
      alt={property.building.name}
      src={property.images[0].url}
      sx={{
        position: "absolute",
        left: left,
        top: top,
        opacity: walkTime === -1 ? 0 : 1,
        filter: excluded ? "grayscale(80%) brightness(60%)" : "",
        transform: `translate(-12px, -12px) scale(${
          excluded ? (excludedByLayout ? 1 / 5 : 2 / 3) : 1
        });`,
        transformOrigin: "50% 50%",
        width: 24,
        height: 24,
        transitionProperty: "transform,opacity,left,filter",
        transitionDuration: "0.3s",
      }}
    />
  );
}

export const applyFilter = (vacant: RentalProperty, filter: Filter) => {
  if (!filterByLayout(vacant, filter.layout)) {
    return false;
  }
  const rent = vacant.contract.rent + vacant.contract.communalFee;
  if (rent < filter.rentMin || rent > filter.rentMax) {
    return false;
  }
  const wt = getWalkTime(vacant, filter.station);
  if (wt < filter.walkTimeMin || wt > filter.walkTimeMax) {
    return false;
  }
  return true;
};

const filterByLayout = (vacant: RentalProperty, layout: number) => {
  if (layout === 0) {
    return true;
  } else if (vacant.apartment.layout === "1R") {
    return layout === 1;
  } else {
    return parseInt(vacant.apartment.layout.charAt(0)) + 1 === layout;
  }
};

const getWalkTime = (vacant: RentalProperty, station: string) => {
  let transits = vacant.building.transits;
  if (station !== "any") {
    transits = transits.filter((t) => t.station === station);
  }
  if (transits.length === 0) {
    return -1;
  }
  return transits.reduce((a, b) => Math.min(a, b.walkMinutes), 100);
};

function inPercentage(value: number) {
  return `${value * 100}%`;
}
